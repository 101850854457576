export function pageTop(pageTopButtonEl, hide) {
  const $pageTopButton = $(pageTopButtonEl);
  if (hide) {
    $pageTopButton.hide();
    $(window).scroll(function () {
      const $scrollTop = $(this).scrollTop();
      if ($scrollTop) {
        if ($scrollTop > 500) {
          $pageTopButton.fadeIn();
        } else {
          $pageTopButton.fadeOut();
        }
      }
    });
  }
  $pageTopButton.click(function () {
    $("body, html").animate(
      {
        scrollTop: 0,
      },
      0
    );
    return false;
  });
}
