import Swiper from "swiper/bundle";
import "swiper/css/bundle";

export function slider() {
  //main
  var slider = new Swiper('.p-top-kv__swiper', {
    slidesPerView: 1,
    centeredSlides: true,
    loop: true,
    loopedSlides: 9,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },

    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },

    breakpoints: {
      992: {
        slidesPerView: 2.2,
        centeredSlides: false,
      }
    }
  });

  //thumbs
  var thumbs = new Swiper('.p-top-kv__swiper-thumbs', {
    slidesPerView: 'auto',
    centeredSlides: true,
    loop: true,
    slideToClickedSlide: true,


    breakpoints: {
      992: {
        centeredSlides: false,
      }
    }
  });

  slider.controller.control = thumbs;
  thumbs.controller.control = slider;

  // results
  var top_results = new Swiper('.p-top-results__slider', {
    slidesPerView: 1.37,
    centeredSlides: true,
    loop: true,
    spaceBetween: 12,
    autoHeight: false,

    pagination: {
      el: ".swiper-pagination",
      clickable: true
    },
  });
}
