export function inputErrorCheck(formEl) {
  $(formEl).each(function () {
    $('span.error').prev('input').addClass('p-contact-contents__error');
    $('span.error').prev('.p-contact-contents__input-container').find('select').addClass('p-contact-contents__error');
    $('span.error').prevAll('select').addClass('p-contact-contents__error');
  });
}

export function inputAutofill() {
  $('#zip-button').on('click', function () {
    AjaxZip3.zip2addr('zip-code', '', 'address01', 'address01');

    //成功時に実行する処理
    AjaxZip3.onSuccess = function () {
      setTimeout(function () {
        $('.address').focus();
      }, 1);
    };

    //失敗時に実行する処理
    AjaxZip3.onFailure = function () {
      alert('郵便番号に該当する住所が見つかりません');
    };

    return false;
  });
}
