export function megaMenu(
  megaMenuEl,
  megaMenuItemEl,
  megaMenuContents
) {
  if ($(megaMenuEl).length && $(megaMenuItemEl).length && $(megaMenuContents)) {
    $(megaMenuEl)
      .children(megaMenuItemEl)
      .hover(function () {
        $(this).toggleClass("active");
        $(this).find(megaMenuContents).toggleClass("active");
      });
    $(megaMenuItemEl)
      .children(megaMenuContents)
      .mouseleave(function () {
        $(this).removeClass("active");
      });
  }
}
