export function tab(tabButtonEl, tabContentsEl) {
  if ($(tabButtonEl).length && $(tabContentsEl).length) {
    $(tabButtonEl)
      .children()
      .on("click", function () {
        const index = $(tabButtonEl).children().index(this);
        $(tabButtonEl).children().removeClass("active");
        $(tabContentsEl).children().removeClass("active");
        $(this).addClass("active");
        $(tabContentsEl).children().eq(index).addClass("active");
      });
  }
}
