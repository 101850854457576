import { scrollAnime, pageScroll } from "./components/scroll";
import { hamburgerMenuButton } from "./components/hamburgerMenuButton";
import { accordion, hamburgerAccordion } from "./components/accordion";
import { tab } from "./components/tab";
import { slider } from "./components/slider";
import { inputAutofill, inputErrorCheck } from "./components/inputError";
import { megaMenu } from "./components/megaMenu";
import { pageTop } from "./components/pageTop";
import { common } from "./components/common";

$(document).ready(function () {

  (() => {
    common();
  })();

  (() => {
    /*
    アニメーション用関数
    */
    scrollAnime();
    pageScroll();

  })();

  (() => {
    /*
    ハンバーガーメニュー用関数
    */
    //ハンバーガーメニューボタンクラス定義
    const buttonEl = "#hamburgerButton";
    //ハンバーガーメニュークラス定義
    const menuEl = "#hamburgerMenu";
    hamburgerMenuButton(buttonEl, menuEl);
  })();

  (() => {
    /*
    スライダー用関数
    */
    slider();
  })();

  (() => {
    /*
    アコーディオン用関数
    */
    //アコーディオンラッパークラス定義
    const accordionEl = ".accordion";
    //アコーディオンボタンクラス定義
    const accordionButtonEl = ".accordionButton";
    //アコーディオンアイテムクラス定義
    const accordionItemEl = ".accordionItem";
    accordion(accordionEl, accordionButtonEl, accordionItemEl);
    hamburgerAccordion();
  })();

  (() => {
    /*
    タブ用関数
    */
    //タブボタンラッパークラス定義
    const tabButtonEl = ".tabButton";
    //タブコンテンツラッパークラス定義
    const tabContentsEl = ".tabContent";
    tab(tabButtonEl, tabContentsEl);
  })();

  (() => {
    /*
    メガメニュー用関数
    */
    //メガメニューラッパークラス定義
    const megaMenuEl = "#megaMenu";
    //メガメニューナビアイテムクラス定義
    const megaMenuItemEl = ".megaMenuItem";
    //メガメニューコンテンツクラス定義
    const megaMenuContents = ".megaMenuContents";
    megaMenu(megaMenuEl, megaMenuItemEl, megaMenuContents);
  })();

  (() => {
    /*
    フォームバリデーション用関数
    */
    //フォームラッパークラス定義
    const formEl = ".p-contact-contents";
    inputErrorCheck(formEl);
    inputAutofill();
  })();

  (() => {
    /*
    ページトップボタン用関数
    */
    //ボタンクラス定義
    const pageTopButtonEl = "#pageTop";
    //初期状態を定義
    const hide = true;
    pageTop(pageTopButtonEl, hide);
  })();

});
