export function common() {
  $(function () {
    $(document).ready(function () {
      $("#yearSelect").change(function () {
        var selectedYear = $(this).val();
        var currentUrl = window.location.href;

        currentUrl = currentUrl.split("/page/")[0];

        if (currentUrl.indexOf("?") !== -1) {
          currentUrl = currentUrl.split("?")[0];
        }

        var url = currentUrl;

        if (selectedYear !== "all") {
          url += "?yr=" + selectedYear;
        }

        window.location.href = url;
      });

      var queryParams = new URLSearchParams(window.location.search);
      var selectedYear = queryParams.get("yr");

      if (selectedYear !== null) {
        $("#yearSelect").val(selectedYear);
      }
    });
  });
}

