export function scrollAnime() {
  $(window).on("scroll", function () {
    const sliderHeight = $(".l-header").height();
    if (sliderHeight - 68 < $(this).scrollTop()) {
      $(".l-header").addClass("headerScroll");
      $(".p-gnavi").addClass("headerScroll");
    } else {
      $(".l-header").removeClass("headerScroll");
      $(".p-gnavi").removeClass("headerScroll");
    }
  });
}

export function pageScroll() {
  $('a[href*="#"]').not('.l-footer__page-top-link').click(function () {
    var elmHash = $(this).attr('href');
    if (window.matchMedia("(min-width: 768px)").matches) {
      var pos = $(elmHash).offset().top - 130;
    } else {
      var pos = $(elmHash).offset().top - 60;
    }
    console.log(pos);
    $('body,html').animate({
      scrollTop: pos
    }, 0);
    return false;
  });

  $(window).on('load', function () {
    let urlHash = location.hash;
    if (urlHash) {
      if (window.matchMedia("(min-width: 768px)").matches) {
        var position = $(urlHash).offset().top - 130;
      } else {
        var position = $(urlHash).offset().top - 60;
      }
      $('html, body').animate({
        scrollTop: position
      }, 0);
    }
  });
}
